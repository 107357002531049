import React from 'react';
import styled from 'styled-components';

import IconGear from '../icons/IconGear';

export default function SettingButton({ onClick = () => {}, }) {

  return (
    <Btn
      title="Settings"
      onClick={onClick}
    >
      <IconGear svgClassName="btn-transition" size={24} />
    </Btn>
  )
}

const Btn = styled.button.attrs({ className: 'wbtn wbtn-center relative btn-transition'})`
  padding: 0;
  height: var(--bar-height);
  width: 60px;
  color: var(--gray-1);
  border-bottom-right-radius: var(--radius-medium);
  border: 1px solid var(--bar-bg);
  background-color: var(--bar-bg);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    left: 0;
    background-color: #3ac4ff;
  }

  &::after {
    left: 2px;
    background-color: #026c9a;
  }

  &:focus,
  &:active,
  &:hover {
    color: white;
    outline: none;
    border: 1px solid var(--bar-bg);
    background-color: var(--bar-bg);
  }
`


