import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './services/slices/rootSlice';

const store = configureStore({
  reducer: {
    root: rootReducer,
  },
});

export default store;
