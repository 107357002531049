import React from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import styled from 'styled-components';

import IconTrash from '../icons/IconTrash';
import IconReload from '../icons/IconReload';
import IconMute from '../icons/IconMute';
import IconMuteAlt from '../icons/IconMuteAlt';
import IconPause from '../icons/IconPause';
import IconPauseAlt from '../icons/IconPauseAlt';
import IconStop from '../icons/IconStop';

export default function RecordingFooter({
  isPause,
  isMute,
  onClickToggleMute = () => {},
  onClickTogglePause = () => {},
  onClickStopAndRecordAgain = () => {},
  onClickDeleteAndBack = () => {},
  onClickStopRecording = () => {},
}) {

  return (
    <Footer>
      <BtnGroup>
        <BtnToggle
          onClick={onClickToggleMute}
          className={isMute ? 'is-selected' : null}
          // title={t('camR_btn_title_mute_unmute')}
        >
          {isMute && (<IconMuteAlt size='19px' />)}
          {!isMute && (<IconMute size='19px' />)}
        </BtnToggle>

        <BtnToggle
          onClick={onClickTogglePause}
          className={isPause ? 'is-selected' : null}
          // title={t('camR_btn_title_pause_play')}
        >
          {isPause && (<IconPauseAlt size='19px' />)}
          {!isPause && (<IconPause size='19px' />)}
        </BtnToggle>
      </BtnGroup>

      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <AlertDialog.Root>
          <AlertDialog.Trigger asChild>
            <BtnToggle style={{ height: 35, width: 37}}>
              <IconReload size='16px' />
            </BtnToggle>
          </AlertDialog.Trigger>

          <AlertDialog.Portal>
            <AlertDialog.Overlay className='mercurius-alert-dialog-overlay'/>
            <AlertDialog.Content className='mercurius-alert-dialog-content'>
              <AlertDialog.Title className='mercurius-alert-dialog-title'>
                {/* {t('camR_delete_video_heading')} */}
                Are you absolutely sure?
              </AlertDialog.Title>
              <AlertDialog.Description className='mercurius-alert-dialog-description'>
                {/* {t('discard_video_description')} */}
                You will lost your recording!
              </AlertDialog.Description>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                <AlertDialog.Cancel asChild>
                  <button className='mercurius-utils-btn mercurius-cancel'>
                    {/* {t('btn_label_no')} */}
                    No
                  </button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button
                    className='mercurius-utils-btn mercurius-delete'
                    title='discard it'
                    onClick={onClickStopAndRecordAgain}
                  >
                    {/* {t('btn_label_yes')} */}
                    Yes
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>

        <AlertDialog.Root>
          <AlertDialog.Trigger asChild>
            <BtnToggle style={{ height: 35, width: 37 }}>
              <IconTrash size='16px' />
            </BtnToggle>
          </AlertDialog.Trigger>

          <AlertDialog.Portal>
            <AlertDialog.Overlay className='mercurius-alert-dialog-overlay'/>

            <AlertDialog.Content className='mercurius-alert-dialog-content'>
              <AlertDialog.Title className='mercurius-alert-dialog-title'>
              {/* {t('camR_delete_video_heading')} */}
              Are you absolutely sure?
              </AlertDialog.Title>
              <AlertDialog.Description className='mercurius-alert-dialog-description'>
                {/* {t('discard_video_description')} */}
                You will lost your recording!
              </AlertDialog.Description>

              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 15 }}>
                <AlertDialog.Cancel asChild>
                  <button className='mercurius-utils-btn mercurius-cancel'>
                    {/* {t('btn_label_no')} */}
                    No
                  </button>
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button
                    className='mercurius-utils-btn mercurius-delete'
                    title='discard it'
                    onClick={onClickDeleteAndBack}
                  >
                    {/* {t('btn_label_yes')} */}
                    Yes
                  </button>
                </AlertDialog.Action>
              </div>

            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      </div>

      <div style={{ paddingRight: 15}}>
        <StopBtn onClick={onClickStopRecording}>
          {/* {t('app_stop_button')} */}
          <IconStop svgClassName='btn-transition' size={19} />
          <span style={{ paddingLeft: 6 }}>Stop</span>
        </StopBtn>
      </div>
    </Footer>
  )
}

const Footer = styled.div.attrs({ className: 'recording-footer relative flex items-center justify-between' })`
  height: var(--bar-height);
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  background-color: var(--bar-bg);

  .flex-auto {
    padding: 0 12px;
  }
`

const BtnToggle = styled.button.attrs({ type: 'button', className: 'wbtn wbtn-center btn-transition' })`
  font-size: 15px;
  color: var(--gray-6);
  padding: 7px 8px;
  border-radius: var(--radius-small);
  border: 1px solid #0185bd;
  background-color: #fff;

  &:focus,
  &:active,
  &:hover {
    border-color: #0185bd;
    background-color: #b2e6fd;
  }

  &.is-selected {
    color: #ff1347;
  }

  svg.svg-icon {
    transition: fill .15s ease;
  }
`;

const BtnGroup = styled.div.attrs({ className: 'flex items-center' })`
  padding-left: 15px;
  gap: 8px;
`

const StopBtn = styled.button.attrs({ className: 'wbtn wbtn-center btn-transition' })`
  color: hsla(347, 86%, 51%, 1);
  padding: 0 15px;
  height: 35px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: var(--radius-small);
  border: 1px solid #0185bd;
  background-color: #fff;

  &:hover {
    color: #fff;
    border-color: hsla(347, 86%, 51%, 1);
    background-color:hsla(347, 86%, 51%, 1);
  }
`
