import React from 'react';
import styled from 'styled-components';

import IconClose from '../icons/IconClose';

export default function CloseButton({
  onClick = () => {},
  iconSize = 23,
  color = '#8a8891',
  hoverColor = '#3e3d42',
}) {

  return (
    <BtnClose
      onClick={onClick}
      style={{
        '--color': color,
        '--hover-color': hoverColor,
      }}
    >
      <IconClose size={iconSize} />
    </BtnClose>
  )
}

const BtnClose = styled.button.attrs({ className: 'wbtn wbtn-center wbtn-lucid'})`
  padding: 5px;
  color: var(--color);
  transition: color .15s linear;

  svg {
    transition: stroke .15s linear;
  }

  &:hover {
    color: var(--hover-color);
  }
`
