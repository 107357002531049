import React from 'react';
import styled from 'styled-components';

import BackButton from './BackButton';
import CloseButton from './CloseButton';

export default function Header({
  hideBackButton,
  isRecording,
  remaining,
  totalTime,
  onClickBack = () => {},
  onClickClose = () => {},
}) {

  function timeFunction(seconds) {
    return new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  return (
    <H>
      <div style={{ paddingLeft: 12, width: 76 }}>
        {!hideBackButton && !isRecording && (
          <BackButton onClick={onClickBack} />
        )}
      </div>

      <div className='flex-auto flex items-center justify-center' style={{ color: '#fff', fontWeight: 400 }}>
        {isRecording && (
          `${timeFunction(remaining)} / ${timeFunction(totalTime)}`
        )}
      </div>

      <div style={{ paddingRight: 12 }}>
        {!isRecording && (
          <CloseButton
            color='var(--gray-1)'
            hoverColor='#fff'
            onClick={onClickClose}
          />
        )}
      </div>
    </H>
  )
}

const H = styled.div.attrs({ className: 'mercurius-header relative flex items-center' })`
  height: var(--bar-height);
  border-top-left-radius: var(--radius-medium);
  border-top-right-radius: var(--radius-medium);
  background-color: var(--bar-bg);
`
