import React, { useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export default function QualityDropdown({
  setVidDimension,
  side = 'bottom',
  arrowColor = '#fff',
}) {

  const videoQualityOptions = [
    {
      label: '360p',
      dimension: { width: 480, height: 360 }
    },
    {
      label: '480p',
      dimension: { width: 858, height: 480 }
    },
    {
      label: '720p',
      dimension: { width: 1280, height: 720 }
    },
    {
      label: '1080p',
      dimension: { width: 1920, height: 1080 }
    },
    {
      label: '4k',
      dimension: { width: 3860, height: 2160 }
    }
  ]

  const [quality, setQuality] = useState('720p');

  const onSelectItem = (value) => {
    setQuality(value);
    const selected = videoQualityOptions.find((item) => item.label === value);
    setVidDimension(selected.dimension)
  }

  return (
    <Container>
      <div className='lable'>
        {/* {t('app_r_quality_label')} */}
        Quality
      </div>
      <div>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <TriggerButton>
              <span>{quality}</span>

              <div className='svg-icon open'>
                <svg height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'>
                  <path d='m.5 4.5 4-4 4 4' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' transform='translate(6 8)'/>
                </svg>
              </div>

              <div className='svg-icon close'>
                <svg height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'>
                  <path d='m8.5.5-4 4-4-4' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' transform='translate(6 8)'/>
                </svg>
              </div>
            </TriggerButton>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className='mercurius-dropdown-menu-content'
              side={side}
              align='end'
              sideOffset={3}
            >
              <DropdownMenu.Item
                className='mercurius-menu-item quality-item'
                textValue='4k'
                onSelect={() => onSelectItem('4k')}
                disabled
              >
                <span style={{ display: 'inline-block' }}>2160p</span>
                <span style={{
                  fontWeight: 500,
                  display: 'inline-block',
                  marginLeft: 5,
                }}>4k</span>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className='mercurius-menu-item quality-item'
                textValue='1080p'
                onSelect={() => onSelectItem('1080p')}
              >
                <span style={{ display: 'inline-block' }}>1080p</span>
                <span style={{
                  fontWeight: 500,
                  display: 'inline-block',
                  marginLeft: 5,
                }}>HD</span>
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className='mercurius-menu-item quality-item'
                textValue='720p'
                onSelect={() => onSelectItem('720p')}
              >
                720p
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className='mercurius-menu-item quality-item'
                textValue='480p'
                onSelect={() => onSelectItem('480p')}
              >
                480p
              </DropdownMenu.Item>

              <DropdownMenu.Item
                className='mercurius-menu-item quality-item'
                textValue='360p'
                onSelect={() => onSelectItem('360p')}
              >
                360p
              </DropdownMenu.Item>

              <DropdownMenu.Arrow style={{ fill: arrowColor }} />
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </Container>
  )
}


const Container = styled.div.attrs({ className: 'relative quality-options flex items-center justify-between' })`
  .lable {
    font-size: 16px;
    font-weight: 400;
    color: var(--gray-8);
  }
`

const TriggerButton = styled.button.attrs({ className: 'wbtn wbtn-center' })`
  font-size: 15px;
  font-weight: 400;
  color: var(--gray-8);
  border-radius: 4px;
  padding: 6px 0px 6px 15px;
  border: 1px solid var(--gray-5);
  background-color: white;

  .svg-icon{
    width: 26px;
    flex-shrink: 0;
  }

  .svg-icon.close {
    display: none;
  }

  &[data-state='open'] {
    .svg-icon.open {
      display: none;
    }
    .svg-icon.close {
      display: block;
    }
  }

  &:hover,
  &:active {
    border-color: var(--gray-6);
    background-color: var(--gray-1);
  }
`
