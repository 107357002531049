import React from 'react';

export default function IconDownload({
  size = 24,
  currentColor = 'currentColor',
  svgClassName,
  svgStyle,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 24 24"
      fill="none"
      className={svgClassName}
      style={{
        '--size': `calc(1px * ${size})`,
        '--vb-height': '24px',
        '--vb-width': '24px',
        width: 'var(--size)',
        height: 'calc((var(--vb-height) / var(--vb-width)) * var(--size))',
        pointerEvents: 'none',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={currentColor} d="M13 10h5l-6 6-6-6h5V3h2v7zm-9 9h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7z"/>
    </svg>
  )
}
