import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import alertStyle from '../alertStyle';
import { setColor } from '../services/slices/rootSlice';


export default function AppRoot ({ options, children }) {
  const dispatch = useDispatch();

  const { brandColor, primaryColor, accentColor, primaryDarkColor, brandDarkColor } = options;

  useEffect(() => {
    dispatch(setColor({
      brandColor,
      primaryColor,
      accentColor,
      primaryDarkColor,
      brandDarkColor
    }));
  }, [dispatch, brandColor, primaryColor, accentColor, primaryDarkColor, brandDarkColor]);

  // these styles are needed out of our widget
  // so we need to add it on document heading
  useEffect(() => {
    document.head.appendChild(alertStyle);
  }, []);

  return (
    <Container
      brandColor={brandColor}
      brandDarkColor={brandDarkColor}
      primaryColor={primaryColor}
      primaryDarkColor={primaryDarkColor}
      accentColor={accentColor}
    >
      {children}
    </Container>
  );
}

const Container = styled.section.attrs({ className: 'mercurius-root'})`
  *,*::before,*::after {
    box-sizing: border-box
  }
  --brand-color: ${({ brandColor }) => brandColor || '#009EE2'};
  --brand-dark-color: ${({ brandDarkColor }) => brandDarkColor || '#0185BD'};
  --primary-color: ${({ primaryColor }) => primaryColor || '#84CC16'};
  --primary-dark-color: ${({ primaryDarkColor }) => primaryDarkColor || '#65A30D'};
  --accent-color: ${({ accentColor }) => accentColor || '#fd5f00'};
  --bar-bg: var(--brand-color);

  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;

  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--gray-8);
  text-align: left;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
`
