import React from 'react';

export default function Visualizer() {
  const canvas = React.useRef();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setWidth(canvas.current.getBoundingClientRect().width);
    setHeight(canvas.current.getBoundingClientRect().height);
  }, [canvas]);

  React.useEffect(() => {
    const canvasContext = canvas.current.getContext('2d');
    const context = new AudioContext()
    const analyserNode = new AnalyserNode(context, { fftSize: 512 });

    // const boundingRect = canvas.current.getBoundingClientRect();

    setupContext();
    drawVisualizer()

    async function setupContext() {
      const audio = await getAudio();

      if (context.state === 'suspended') {
        await context.resume()
      }

      const source = context.createMediaStreamSource(audio);
      source.connect(analyserNode);
    }

    function getAudio () {
      return navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          latency: 0,
        }
      })
    }

    function drawVisualizer() {
      requestAnimationFrame(drawVisualizer)

      const bufferLength = analyserNode.frequencyBinCount
      const dataArray = new Uint8Array(bufferLength)
      analyserNode.getByteFrequencyData(dataArray)

      // const width = cContext.current.width
      // const height = cContext.current.height
      // const barWidth = (width / bufferLength);
      const barWidth = 10;

      //const canvasContext = canvas.current.getContext('2d')
      canvasContext.clearRect(0, 0, width, height)

      dataArray.forEach((item, index) => {
        const y = item / 255 * height;
        const x = barWidth * index;
        canvasContext.fillStyle = `hsla(${y / height * 400}, 100%, 50%, 0.3)`;
        canvasContext.fillRect(x, height - y, barWidth, y);
      })
    }

  }, [canvas, width, height]);

  return (
    <canvas
      ref={canvas}
      width={40}
      height={38}
      style={{
        borderRadius: '0.25rem',
        position: 'absolute',
        top: 0,
        left: 0,
        borderWidth: 0,
        zIndex: 15
      }}
    />
  )
}
