import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../services/axios';

import Container from '../layout/Container';
import PreviewContainer from '../layout/PreviewContainer';
import DoneMessage from '../components/DoneMessage';
import FeedContainerLight from '../components/FeedContainerLight';
import PreviewFooter from '../components/PreviewFooter';
import CountAnim from '../components/CountAnim';
import RecordingFooter from '../components/RecordingFooter';
import Header from '../components/Header';
import LoaderJelly from '../components/LoaderJelly';

import config from '../services/config';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { getSettingsState } from '../services/slices/rootSlice'

export default function ScreenRecording({ options }) {

  const { isFirefox, isSafari } = options;

  const initalState = useSelector(getSettingsState);
  const navigate = useNavigate();

  // state & ref
  const gdm_stream = useRef(null);
  const gdm_audio = useRef(null);
  const gdm_mixedStream = useRef(null);
  const gdm_recorder = useRef(null);
  const videoFeedRef = useRef(null);
  const videoPreviewRef = useRef(null);
  const downLoadRef = useRef(null);
  const downLoadLink = useRef(null);
  const timeoutId = useRef(null);
  const rDuration = useRef(60);
  const firstPoster = useRef('poster_url');
  const mediaPostUrl = useRef(null);
  const chunkSplit = useRef(null);
  const sessionId = useRef(null);
  const recordId = useRef(null);
  const videoBitsPerSecond = useRef(null);
  const audioBitsPerSecond = useRef(null);
  const recodingType = useRef('widget-screen');
  const chunkReportUrl = useRef(null);
  const sessionRemoveUrl = useRef(null);
  const sessionEditUrl = useRef(null);
  const sessionTargetUrl = useRef(null);
  const recordStartTime = useRef(0);
  const recordEndTime = useRef(0);
  const chunksLog = useRef([]);
  const intervalRef = useRef(null);
  const isEndRecordDetaSend = useRef(null);


  const [resume, setResume] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [isAutoStart, setIsAutoStart] = useState(true);
  const [splashCount, setSplashCount] = useState(3);
  const [beforeStart, setBeforeStart] = useState(false);
  const [recordDone, setRecordDone] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [chunkCount, setChunkCount] = useState(0);
  const [resChunkCount, setResChunkCount] = useState(0);
  const [blobNum, setBlobNum] = useState([]);
  const [uploadState, setUploadState] = useState(false);
  const [mergedAssets, setMergedAssets] = useState(null);
  const [isMute, setIsMute] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isEndRecordRequest , setIsEndRecordRequest] = useState(false);
  // eslint-disable-next-line
  const [_ , setIsChunkReportDone] = useState(false);


  const userId = 'user_id_01';
  const codecs = isFirefox
      ? 'video/webm;codecs="vp8,opus"'
      : isSafari
          ? 'video/mp4'
          : 'video/webm;codecs="vp9,opus"';


  let countBolb = null;
  let fileName = null;
  let countStatus = null;
  const startAfterTime = splashCount * 1000;
  isEndRecordDetaSend.current = isEndRecordRequest;

  const isLinkButtonShow = isUserLoggedIn && isRegistered;

  const onClickBack = () => {
    setStartCapture(false);
    navigate('/mw-recorder-selection');
  }

  async function setupGdmStream () {
    try {
      gdm_stream.current = await navigator.mediaDevices.getDisplayMedia({
        video: {
          cursor: "always",
          width: { ideal: 1920, max: 1920 },
          height: { ideal: 1080, max: 1080 },
          frameRate: 60,
        },
      });

      gdm_audio.current = await navigator.mediaDevices.getUserMedia({
        audio: {
          echoCancellation: true,
          noiseSupperssion: true,
          sampleRate: 44100,
        }
      })

      if (videoFeedRef.current) {
        videoFeedRef.current.srcObject = gdm_stream.current;
      }

      dumpOptionsInfo();
    }
    catch(err) {
      console.warn(err.name);
      navigate('/mw-recorder-selection');
    }
  }

  async function getScreenRecorder() {
    await setupGdmStream();

    if (gdm_stream.current && gdm_audio.current) {
      startCountDown();

      const timeOut = setTimeout(() => {
        setStartCapture(true);

        gdm_mixedStream.current = new MediaStream([
          ...gdm_stream.current.getTracks(),
          ...gdm_audio.current.getTracks(),
        ]);

        const options = {
          // mimeType: 'video/webm;codecs="vp9,opus"',
          mimeType: codecs,
          videoBitsPerSecond: videoBitsPerSecond.current, // See https://developers.google.com/media/vp9/settings/vod#recommended_settings
          audioBitsPerSecond: audioBitsPerSecond.current,
        }

        const cSplit = chunkSplit.current * 1000;
        countBolb = 0;
        fileName = '';
        setBlobNum([]);
        countStatus = 0;
        chunksLog.current = [];
        setIsChunkReportDone(false);
        setIsEndRecordRequest(false);

        sessionId.current = Date.now().toString(36) + Math.random().toString(36).substring(2);
        recordId.current = Date.now().toString(31) + Math.random().toString(31).substring(2);
        recordStartTime.current = new Date(Date.now()).toISOString();  // start Timestamp of recoding;

        gdm_recorder.current = new MediaRecorder(gdm_mixedStream.current, options);
        console.log(`Screen recorder started with ${gdm_recorder.current.mimeType}`)
        gdm_recorder.current.ondataavailable = handleDataAvailable;
        gdm_recorder.current.onstop = handleStop;
        gdm_recorder.current.start(cSplit);

        startTimeLimit(rDuration.current);
        clearTimeout(timeOut);
      }, startAfterTime);
    }
  }

  function dumpOptionsInfo() {
    const videoTrack = gdm_stream.current.getVideoTracks()[0];
    console.info(JSON.stringify(videoTrack.getSettings(), null, 2));

    videoTrack.onended = function() {
      stopRecording();
    }
  }

  const startTimeCount = useCallback((seconds = rDuration.current) => {
    intervalRef.current = setInterval(() => {
      const newCounter = seconds--;
      if (newCounter >= 0) {
        setRemaining((s) => s + 1);
      } else {
        clearInterval(intervalRef.current);
        setRemaining(0)
      }
    }, 1000);
  }, []);

  const startTimeLimit = (in_second) => {
    timeoutId.current = setTimeout(() => {
      stopRecording();
    }, in_second * 1000);
  }

  const startCountDown = () => {
    setBeforeStart(true);

    const interval = setInterval(() => {
      setSplashCount((prev) => {
        if ( prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
        }
      })
    }, 1000);
  }


  const startRecording = async () => {
    await getScreenRecorder();
  }


  function stopRecording () {
    gdm_recorder.current.stop();
  }

  function handleStop (e) {
    recordEndTime.current = new Date(Date.now()).toISOString();
    gdm_stream.current.getTracks().forEach(track => track.stop());
    gdm_audio.current.getTracks().forEach(track => track.stop());

    setRecordDone(true);
    setStartCapture(false);
    setBeforeStart(false);
    setSplashCount(3);

    setResume(0);
    setRemaining(0)
    clearInterval(intervalRef.current);
    clearTimeout(timeoutId.current);
  }

  function handleDataAvailable (e) {
    if (e.data && e.data.size > 0) {
      // console.log('blob', e.data);
      setChunkCount((cc) => cc + 1);

      countBolb = countBolb + 1;
      setBlobNum((state) => [...state, countBolb]);
      fileName = `${countBolb}.webm`;

      let logData = {
        dateTimeStart: new Date(Date.now()).toISOString(),
        dateTimeStop: '',
        video: fileName,
        size: e.data.size,
        responseStatus: '',
      }

      chunksLog.current.push(logData);

      let formData = new FormData();
      formData.append('sessionId', sessionId.current);
      formData.append('recordingId', recordId.current);
      formData.append('userId', userId);
      formData.append('recodingType', recodingType.current);
      formData.append('video', fileName);
      formData.append(`video-blob`, e.data);

      axios.post(mediaPostUrl.current, formData).then((res) => {

        if (res.data) {
          countStatus = countStatus + 1;
          fileName = `${countStatus}.webm`;
          const chunkObj = chunksLog.current.find((elem) => elem.video === fileName);

          // eslint-disable-next-line
          Object.assign(chunkObj, {
            dateTimeStop: new Date(Date.now()).toISOString(),
            responseStatus: res.data.status,
          });
        }

        if (res.data.status === 200) {
          if (res.data.preview) {
            firstPoster.current = res.data.preview
            if (firstPoster) {
              videoPreviewRef.current.poster = firstPoster.current;
            }
          }
          console.log('upload-ended');
          setResChunkCount((rcc) => rcc + 1);
        }
      })
      .catch((err) => {
        console.warn(err);
      })
    }
  }

  function startNewRecording() {
    setIsAutoStart(false);
    console.log('new record');
    axios.post(config.apiUrls.createRecordingSession, null).then((res) => {
      if (res.data.settings.serviceAvailable && (res.status === 200 || res.status === 201)) {
        audioBitsPerSecond.current = res.data.settings.audioBitsPerSecond;
        rDuration.current = res.data.settings.maxRecordingTime;
        chunkSplit.current = res.data.settings.postChunkSize;
        mediaPostUrl.current = res.data.settings.postUrl;
        chunkReportUrl.current = res.data.settings.recordingSessionChunkReportUrl;
        videoBitsPerSecond.current = res.data.settings.videoBitsPerSecond;
        sessionRemoveUrl.current = res.data.settings.recordingSessionRemoveUrl;
        sessionEditUrl.current = res.data.settings.recordingSessionEditTargetUrl;
        sessionTargetUrl.current = res.data.settings.recordingSessionFinishedTargetUrl;
        startRecording();
      } else {
        // if service is not available
        // notAvailableCallBack();
      }
    }).catch((err) => console.warn(err));
  }

  const toggleMute = () => {
    setIsMute(!isMute);

    const audioStream = gdm_audio.current;

    if (audioStream) {
      const audioTrack = audioStream.getTracks().find((track) => track.kind === 'audio');

      if (audioTrack.enabled) {
        audioTrack.enabled = false;
        console.log('=> audio disabled');
      } else {
        audioTrack.enabled = true;
        console.log('=> audio enabled');
      }
    }
  }

  const togglePause = () => {
    setIsPause(!isPause);
    const recorder = gdm_recorder.current;

    if (recorder) {
      if (recorder.state === 'recording') {
        recorder.pause();
        setResume(remaining);
        clearInterval(intervalRef.current);
        clearTimeout(timeoutId.current);
        console.log('=> time counting paused');

      } else if (recorder.state === 'paused') {
        recorder.resume();
        startTimeCount((rDuration.current - resume) - 1);
        startTimeLimit((rDuration.current - resume) + 1);
        setResume(0);
        console.log('=> time counting pasue resumed');
      }
    }
  }

  const resetVideoRef = () => {
    videoPreviewRef.current.poster = '';
    videoPreviewRef.current.src = '';
    videoPreviewRef.current.load();
    firstPoster.current = 'poster_url';
  }

  const resetState = () => {
    setIsAutoStart(false);
    setRecordDone(false);
    setChunkCount(0);
    setResChunkCount(0);
    setMergedAssets(null);
    setBlobNum([]);
    setUploadState(false);
    setIsMute(false);
    setIsPause(false);
    setResume(0);
    setRemaining(0);
  }

  /**
  * waifor function
  * it will wait for flag until the flag get true
  */
  function waitFor (condiFunc) {
    const poll = (resolve) => {
      if (condiFunc()) {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 500);
      }
    }
    return new Promise(poll);
  }


  /**
  * Trash icon button
  * appear during recording footer bottom of video
  * delete video and reset state and navigate to root
  */
  const deleteAndBack = () => {
    stopRecording();
    setIsDeleting(true);

    waitFor(() => isEndRecordDetaSend.current === true).then(() => {
      axios.delete(sessionRemoveUrl.current).then((res) => {
        if (res.statusText === 'OK') {
          setIsDeleting(false);
          resetState();
          resetVideoRef();
          navigate('/');
        }
      });
    });
  }

  /**
  * Repeat icon button
  * appear during recording footer bottom of video
  * delete video and reset state and start new recording
  */
  const stopAndRecordAgain = () => {
    stopRecording();
    setIsDeleting(true);

    waitFor(() => isEndRecordDetaSend.current === true).then(() => {
      axios.delete(sessionRemoveUrl.current).then((res) => {
        if (res.statusText === 'OK') {
          setIsDeleting(false);
          resetState();
          resetVideoRef();
          clearInterval(intervalRef.current);
          startNewRecording();
        }
      });
    });
  }

  /**
  * Trash icon button
  * appear in record-finished footer bottom of preview video
  * delete video and reset state and start recording again
  */
  const deleteVideo = () => {
    setIsDeleting(true);
    axios.delete(sessionRemoveUrl.current).then((res) => {
      if (res.status === 200) {
        setIsDeleting(false);
        resetState();
        resetVideoRef();
        startNewRecording();
      }
    });
  }

  /**
  * Repeat icon button
  * appear in record-finished footer bottom of preview video
  * reset state and start recording again
  */
  const repeatRecording = () => {
    setIsDeleting(true);

    const timeOut = setTimeout(() => {
      setIsDeleting(false);
      resetState();
      resetVideoRef();
      startNewRecording();
      clearTimeout(timeOut);
    }, 400);
  }

  const copyLink = () => {
    copyTextToClipboard(sessionTargetUrl.current).then(() => {
      setIsCopied(true);
      const timeOut = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(timeOut);
      }, 1500);
    })
    .catch((err) => console.warn(err));
  };

  // recording last call back
  const endRecordReq = useCallback(() => {
    const totalBlobNum = blobNum[blobNum.length - 1];

    if ( chunkCount !== 0 && chunkCount === resChunkCount) {
      if (totalBlobNum <= resChunkCount) {
        const formData = new FormData();
        formData.append('sessionId', sessionId.current);
        formData.append('userId', userId);
        formData.append('recordingId', recordId.current);
        formData.append('recordingDone', true);

        axios.post(mediaPostUrl.current, formData).then((res) => {
          if (res.status === 200) {
            setIsEndRecordRequest(true);

            if (res.data) {
              setMergedAssets(res.data);
              downLoadLink.current = res.data.previewVideo;
              videoPreviewRef.current.src = res.data.previewVideo;
            }
            console.log('Recording Successfully Done! Preview is ready.');
          }
        }).catch( (err) => {console.log(err)})
      }
    }

  },[chunkCount, resChunkCount, blobNum]);

  // chunk repots
  const chunkReportReq = useCallback(() => {
    const formData = new FormData();
    formData.append('recodingType', recodingType.current);
    formData.append('dateTimeStart', recordStartTime.current);
    formData.append('dateTimeStop', recordEndTime.current);
    formData.append('chunks', JSON.stringify(chunksLog.current, null, 2));

    axios.put(chunkReportUrl.current, formData).then((res) => {
      if (res.status === 201) {
        setIsChunkReportDone(true);
        console.log('Chunks report has been sent successfully');
      }
    }).catch((err) => console.warn(err))

  },[]);

  // start time counting when record start
  useEffect(() => {
    if (startCapture) {
      startTimeCount(rDuration.current);
    }
    return () => {
      clearInterval(intervalRef.current);
    }
  }, [startCapture, startTimeCount]);

  useEffect(() => {
    if (recordDone) {
      endRecordReq();
    }
  }, [endRecordReq, recordDone]);

  // call when isChunkReportDone true
  useEffect(() => {
    if (isEndRecordRequest) {
      chunkReportReq();
    }
  }, [chunkReportReq, isEndRecordRequest]);

  useEffect(() => {
    if ( chunkCount !== 0 && chunkCount === resChunkCount && recordDone) {
      setUploadState(true);
    } else {
      setUploadState(false)
    }
  }, [chunkCount, resChunkCount, recordDone]);

  // download button
  useEffect(() => {
    if (downLoadRef.current) {
      downLoadRef.current.href = downLoadLink.current;
      downLoadRef.current.download = 'cam_vid.webm';
    }
  }, [mergedAssets, uploadState, downLoadRef]);



  useEffect(() => {
    if (initalState) {
      setIsUserLoggedIn(initalState.userIsLoggedIn);
      setIsRegistered(initalState.userIsRegistered);
    }

    // it will only start when first land
    if (isAutoStart) {
      axios.post(config.apiUrls.createRecordingSession, null).then((res) => {
        // check if service is available
        if (res.data.settings.serviceAvailable && (res.status === 200 || res.status === 201)) {
          audioBitsPerSecond.current = res.data.settings.audioBitsPerSecond;
          rDuration.current = res.data.settings.maxRecordingTime;
          chunkSplit.current = res.data.settings.postChunkSize;
          mediaPostUrl.current = res.data.settings.postUrl;
          chunkReportUrl.current = res.data.settings.recordingSessionChunkReportUrl;
          videoBitsPerSecond.current = res.data.settings.videoBitsPerSecond;
          sessionRemoveUrl.current = res.data.settings.recordingSessionRemoveUrl;
          sessionEditUrl.current = res.data.settings.recordingSessionEditTargetUrl;
          sessionTargetUrl.current = res.data.settings.recordingSessionFinishedTargetUrl;
          startRecording();
        } else {
          // if service is not available
          // notAvailableCallBack();
        }
      }).catch((err) => {
        console.warn(err)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initalState, isAutoStart]);


  return (
      <Container>
        <Header
          isRecording={startCapture}
          hideBackButton={startCapture}
          onClickBack={onClickBack}
          onClickClose={() => navigate('/')}
          remaining={remaining}
          totalTime={rDuration.current && rDuration.current}
        />

        {!recordDone && (
          <FeedContainerLight
            ref={videoFeedRef}
            isRecording={startCapture}
         />
        )}

        {!recordDone && startCapture && (
          <RecordingFooter
            isPause={isPause}
            isMute={isMute}
            onClickToggleMute={toggleMute}
            onClickTogglePause={togglePause}
            onClickStopAndRecordAgain={stopAndRecordAgain}
            onClickDeleteAndBack={deleteAndBack}
            onClickStopRecording={stopRecording}
          />
        )}

        <PreviewContainer
          isVisible={recordDone}
          ref={videoPreviewRef}
        />

        {/* footer after recording */}
        {recordDone && (
          <PreviewFooter
            ref={downLoadRef}
            isLinkButtonShow={isLinkButtonShow}
            chunkCount={chunkCount}
            resChunkCount={resChunkCount}
            mergedAssets={mergedAssets}
            uploadState={uploadState}
            sesTargetUrl={sessionTargetUrl.current}
            sesEditUrl={sessionEditUrl.current}
            onClickDeleteVideo={deleteVideo}
            onClickRecordAgain={repeatRecording}
            onClickCopyLink={copyLink}
          />
        )}

        {beforeStart && splashCount > 0 && (
          <CountAnim boundary number={splashCount} />
        )}

        {isDeleting && (
          <div className='mercurius-overlay absolute flex items-center justify-center'>
            <LoaderJelly
              size={50}
              color='#fff'
            />
          </div>
        )}

        {isCopied && (
          <div className='mercurius-overlay absolute flex items-center justify-center'>
            {/* {t('camR_alert_link_copied')} */}
            <p style={{ margin: 0, color: '#fff', fontSize: 17 }}>Video Link copied to clipboard</p>
          </div>
        )}

         {/* {t('recorder_preview_message')} */}
        {recordDone && !isDeleting && (
          <DoneMessage
            isHidden={mergedAssets && uploadState}
            message="Awesome video 😎 - We need a few more seconds, we're almost done..."
          />
        )}
      </Container>
  );
}
