import React from 'react';
import styled from 'styled-components';

export default function Container({ children }) {
  return (
    <Outer>
      <Inner>
        {children}
      </Inner>
    </Outer>
  )
}

const Outer = styled.div.attrs({ className: 'relative flex flex-column aitems-center justify-center mercurius-container'})`
  padding: 16px;
  width: 100%;
  max-width: 588px;
  background: transparent;
  border-radius: var(--radius-medium);
  border: 1px solid rgba(0, 0, 0, 0.11);
  box-shadow: rgb(0, 0, 0, 0.04) 0px 4px 17px;
`

const Inner = styled.div.attrs({ className: 'relative flex flex-column'})`
  width: 100%;
  border-radius: var(--radius-medium);
`
