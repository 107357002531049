import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :host {
    --green-400: #4ade80;
    --green-500: #22c55e;
    --green-600: #16a34a;
    --red-400: #fb7185;
    --red-500: #f43f5e;
    --red-600: #e11d48;

    --gray-1: #f2f2f3;
    --gray-2: #d8d7da;
    --gray-3: #bebdc2;
    --gray-4: #a4a2a9;
    --gray-5: #8a8891;
    --gray-6: #706e77;
    --gray-7: #57565d;
    --gray-8: #3e3d42;
    --gray-9: #252528;
    --gray-10: #0c0c0d;

    --blue: #009EE2;
    --blue-dark: #047eb3;
    --orange: #ff7000;

    --w-variant-1: #f5f4e8;
    --w-variant-2: #edede4;
    --w-variant-3: #e3e1c8;
    --w-variant-4: #e6f3f7;

    --black-transparent-1: hsla(217, 33%, 17%, 0.7);
    --black-transparent-2: hsla(217, 33%, 17%, 0.5);
    --elevation-rgb: 60, 64, 67;
    --elevation-1: rgba(var(--elevation-rgb), .3) 0 1px 2px 0, rgba(var(--elevation-rgb), .15) 0 2px 6px 2px;
    --elevation-2: 0 3px 6px rgba(9, 9, 9, .16), 0 3px 6px rgba(10, 10, 10, .23);
    --elevation-3: rgba(60, 68, 67, .3) 0 1px 2px 0, rgba(60, 68, 67, .15) 0 2px 6px 2px;
    --elevation-4: rgba(60, 68, 67, .15) 0 1px 2px 0, rgba(60, 68, 67, .15) 0 2px 6px 2px;

    --bar-height: 50px;
    --radius-small: 4px;
    --radius-medium: 6px;
    --radius-large: 8px;

    --font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --body-font-family: var(--font-sans-serif);
    --body-font-size: 16px;
    --body-font-weight: 400;
    --body-line-height: 1.14;
  }

  a { background-color: transparent}
  b,strong {font-weight: bolder}
  small {font-size: 80%}

  textarea {
    overflow: auto;
  }

  img {
    border-style: none;
    max-width: 100%;
    height: auto;
  }

  .inline       { display: inline }
  .block        { display: block }
  .inline-block { display: inline-block }
  .table        { display: table }
  .table-cell   { display: table-cell }
  .overflow-hidden { overflow: hidden }
  .overflow-scroll { overflow: scroll }
  .overflow-auto   { overflow: auto }
  .fit { max-width: 100% }
  .mw-100 { max-width: 100% }
  .w-100 { width: 100% }
  .relative { position: relative }
  .absolute { position: absolute }
  .fixed    { position: fixed }
  .top-0    { top: 0 }
  .right-0  { right: 0 }
  .bottom-0 { bottom: 0 }
  .left-0   { left: 0 }

  .flex { display: flex }
  .inline-flex { display: inline-flex }

  .flex-column  { flex-direction: column }
  .flex-wrap    { flex-wrap: wrap }

  .items-start    { align-items: flex-start }
  .items-end      { align-items: flex-end }
  .items-center   { align-items: center }
  .items-baseline { align-items: baseline }
  .items-stretch  { align-items: stretch }

  .self-start    { align-self: flex-start }
  .self-end      { align-self: flex-end }
  .self-center   { align-self: center }
  .self-baseline { align-self: baseline }
  .self-stretch  { align-self: stretch }

  .justify-start   { justify-content: flex-start }
  .justify-end     { justify-content: flex-end }
  .justify-center  { justify-content: center }
  .justify-between { justify-content: space-between }
  .justify-around  { justify-content: space-around }
  .justify-evenly  { justify-content: space-evenly }

  .content-start   { align-content: flex-start }
  .content-end     { align-content: flex-end }
  .content-center  { align-content: center }
  .content-between { align-content: space-between }
  .content-around  { align-content: space-around }
  .content-stretch { align-content: stretch }
  .flex-auto {flex: 1 1 auto; min-width: 0; min-height: 0;}
  .flex-none { flex: none }
  .transition {transition: all .15s linear};

  .btn-transition {
    transition: all 0.15s ease;
  }

  .wbtn {
    cursor: pointer;
    font-family: var(--body-font-family);
    line-height: 1;

    &:focus {
      outline: none;
    }
  }

  .wbtn-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .wbtn-lucid {
    padding: 0;
    border: 1px solid transparent;
    background-color: transparent;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border-color: transparent;
      background-color: transparent;
    }
  }


  .wbtn-red {
    color: white;
    height: 40px;
    padding: 0 17px;
    border-radius: var(--radius-small);
    border: 1px solid var(--red-500);
    background-color: var(--red-500);

    &:focus,
    &:active,
    &:hover {
      outline: none;
      border: 1px solid var(--red-600);
      background-color: var(--red-600);
    }
  }

  .select,
  .input {
    font-family: var(--body-font-family);
    margin: 0;
    width: 100%;
    font-size: var(--body-font-size);
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    color: var(--gray-8);
    border-radius: 3px;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid var(--gray-5);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .select {
    display: inline-block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    vertical-align: middle;
    background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center/8px 10px no-repeat;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: var(--green-500);
    }
  }

  .mercurius-overlay {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: hsla(217, 33%, 17%, 0.7);
  }

  .mercurius-toast-viewport {
    --viewport-padding: 25px;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: var(--viewport-padding);
    gap: 10px;
    width: 390px;
    max-width: 100vw;
    margin: 0;
    list-style: none;
    z-index: 2147483647;
    outline: none;
  }

  .mercurius-toast-root {
    background-color: white;
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    padding: 15px;
    display: grid;
    grid-template-areas: 'title action' 'description action';
    grid-template-columns: auto max-content;
    column-gap: 15px;
    align-items: center;
  }
  .mercurius-toast-root[data-state='open'] {
    animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .mercurius-toast-root[data-state='closed'] {
    animation: hide 100ms ease-in;
  }
  .mercurius-toast-root[data-swipe='move'] {
    transform: translateX(var(--radix-toast-swipe-move-x));
  }
  .mercurius-toast-root[data-swipe='cancel'] {
    transform: translateX(0);
    transition: transform 200ms ease-out;
  }
  .mercurius-toast-root[data-swipe='end'] {
    animation: swipeOut 100ms ease-out;
  }

  .mercurius-toast-title {
    grid-area: title;
    margin-bottom: 5px;
    font-weight: 500;
    color: var(--slate12);
    font-size: 15px;
  }

  .mercurius-toast-description {
    grid-area: description;
    margin: 0;
    color: var(--slate11);
    font-size: 13px;
    line-height: 1.3;
  }

  .mercurius-toast-action {
    grid-area: action;
  }
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes swipeOut {
    from {
      transform: translateX(var(--radix-toast-swipe-end-x));
    }
    to {
      transform: translateX(calc(100% + var(--viewport-padding)));
    }
  }
`;

export default GlobalStyles;
