import React from 'react';

export default function IconChevron({
  size = 24,
  currentColor = 'currentColor',
  svgClassName,
  svgStyle,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 20 20"
      fill="none"
      className={svgClassName}
      style={{
        '--size': `calc(1px * ${size})`,
        '--vb-height': '20px',
        '--vb-width': '20px',
        width: 'var(--size)',
        height: 'calc((var(--vb-height) / var(--vb-width)) * var(--size))',
        pointerEvents: 'none',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={currentColor} d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
    </svg>
  )
}
