import React from 'react';
import styled, { keyframes } from 'styled-components';

import CloseButton from './CloseButton';
import Visualizer from './Visualizer';
import QualityDropdown from './QualityDropdown';

export default function CamSettings({
  isModalOpen,
  setIsModalOpen,
  videoValue,
  audioValue,
  videoOptions = [],
  audioOptions = [],
  onChangeVideo = () => {},
  onChangeAudio = () => {},
  setVidDimension,
}) {
  return (
    <Container
      className={isModalOpen ? 'open' : 'off'}
    >
      <Bar>
        <div>Settings</div>
        <CloseButton
          iconSize={22}
          color='hsla(253, 10%, 30%, 1)'
          hoverColor='hsla(253, 10%, 40%, 1)'
          onClick={() => setIsModalOpen(false)}
        />
      </Bar>

      <div style={{ padding: 16 }}>
        <InputGroup>
          <InputGroupIcon>
            <svg height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg'>
              <path d='m2.5.5h6c1.1045695 0 2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2h-6c-1.1045695 0-2-.8954305-2-2v-4c0-1.1045695.8954305-2 2-2zm8 3 2.4-1.8c.4418278-.33137085 1.0686292-.2418278 1.4.2.1298221.17309617.2.38362979.2.6v4c0 .55228475-.4477153 1-1 1-.2163702 0-.4269038-.07017787-.6-.2l-2.4-1.8z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' transform='translate(3 6)'/>
            </svg>
          </InputGroupIcon>

          <div className='flex-auto'>
            <select
              className="select"
              defaultValue={videoValue}
              onChange={onChangeVideo}
            >
              {videoOptions && videoOptions.flat().map(({ deviceId, label }) => {
                return <option key={deviceId} value={deviceId}>{label}</option>
              })}
            </select>
          </div>
        </InputGroup>

        <InputGroup>
          <InputGroupIcon>
            <svg height='21' viewBox='0 0 21 21' width='21' xmlns='http://www.w3.org/2000/svg' style={{ position: 'relative', zIndex: 20 }}>
              <g fill='none' fillRule='evenodd' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' transform='translate(5 2)'>
                <path d='m5.38916302.61501045.11083698-.00409775c1.5967308-.05903257 2.93899223 1.18751813 2.9980248 2.78424894.00131669.0356141.0019752.07124949.0019752.10688793v3.99795043c0 1.65685425-1.34314575 3-3 3s-3-1.34314575-3-3v-3.88703773c0-1.61372368 1.27654106-2.93833174 2.88916302-2.99795182z'/>
                <path d='m10.5 7.5c0 2.7614237-2.23857625 5-5 5-2.6887547 0-4.88181811-2.1223067-4.99538049-4.78311038l-.00461951-.21688962'/>
                <path d='m5.5 12.5v4'/>
              </g>
            </svg>
          </InputGroupIcon>

          {isModalOpen && (
            <Visualizer />
          )}

          <div className='flex-auto'>
            <select
              className="select"
              defaultValue={audioValue}
              onChange={onChangeAudio}
            >
              {audioOptions && audioOptions.flat().map(({ deviceId, label }) => {
                return <option key={deviceId} value={deviceId}>{label}</option>
              })}
            </select>
          </div>
        </InputGroup>

        <QualityDropdown
          setVidDimension={setVidDimension}
        />

      </div>
    </Container>
  )
}

const slideInRight = keyframes`
  from {
    opacity: 0;
    transform: translate3d(10%, 0, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const Container = styled.div.attrs({ className: 'absolute top-0 right-0 mercurius-cam-settings'})`
  height: 100%;
  width: 350px;
  animation-duration: .3s;
  border-top-right-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  background: hsla(253, 4%, 75%, 0.7);

  &.off {
    display: none;
  }

  &.open {
    display: block;
    animation-name: ${slideInRight};
  }
`

const Bar = styled.div.attrs({ className: 'relative flex items-center justify-between'})`
  height: var(--bar-height);
  padding-right: 6px;
  border-top-right-radius: var(--radius-medium);
  border-bottom: 1px solid rgba(0,0,0,0.3);
  background-color: hsla(253, 10%, 60%, 1);

  > div {
    padding-left: 16px;
    font-size: 15px;
    color: var(--gray-6);
    color: hsla(253, 10%, 30%, 1);
  }
`
const InputGroup = styled.div.attrs({ className: 'relative flex' })`
  --mb: ${({ mb }) => mb || 16 };
  margin-bottom: calc(1px * var(--mb));
`

const InputGroupIcon = styled.div.attrs({ className: 'flex items-center justify-center relative' })`
  color: var(--gray-7);
  height: 38px;
  flex-shrink: 0;
  flex-basis: 41px;
  max-width: 41px;
  margin-right: 5px;
  border: 1px solid var(--gray-5);
  border-radius: .25rem;
  line-height: 1.5;
  background-color: #fff;
`
