import React, { useEffect, useState, useRef, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from '../services/axios';

// components
import Container from '../layout/Container';
import PreviewContainer from '../layout/PreviewContainer';
import DoneMessage from '../components/DoneMessage';
import Header from '../components/Header';
import FeedContainer from '../components/FeedContainer';
import PreviewFooter from '../components/PreviewFooter';
import RecordingFooter from '../components/RecordingFooter';
import CountAnim from '../components/CountAnim';
import LoaderJelly from '../components/LoaderJelly';
import CamSettings from '../components/CamSettings';

//
import config from '../services/config';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { getSettingsState } from '../services/slices/rootSlice'
import useDeviceList from '../hooks/useDeviceList';


export default function CameraRecording({ options }) {

  const { isFirefox } = options;

  const initalState = useSelector(getSettingsState);

  // hooks
  const navigate = useNavigate();
  const {
    audioValue,
    videoValue,
    videoOptions,
    audioOptions,
    setVideoValue,
    setAudioValue,
  } = useDeviceList();

  // state & ref
  const videoFeedRef = useRef(null);
  const videoPreviewRef = useRef(null);
  const recorderStream = useRef(null);
  const mediaRecorder = useRef(null);
  const downLoadRef = useRef(null);
  const downLoadLink = useRef(null);
  const timeoutId = useRef(null);
  const rDuration = useRef(60);
  const firstPoster = useRef('poster_url');
  const mediaPostUrl = useRef(null);

  const chunkSplit = useRef(null);
  const sessionId = useRef(null);
  const recordId = useRef(null);

  const videoBitsPerSecond = useRef(null);
  const audioBitsPerSecond = useRef(null);
  const recodingType = useRef('widget-cam');
  const chunkReportUrl = useRef(null);
  const sessionRemoveUrl = useRef(null);
  const sessionEditUrl = useRef(null);
  const sessionTargetUrl = useRef(null);
  const recordStartTime = useRef(0);
  const recordEndTime = useRef(0);
  const chunksLog = useRef([]);
  const isEndRecordDetaSend = useRef(null);


  const intervalRef = useRef(null);
  const [resume, setResume] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const [splashCount, setSplashCount] = useState(3);
  const [beforeStart, setBeforeStart] = useState(false);
  const [recordDone, setRecordDone] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [chunkCount, setChunkCount] = useState(0);
  const [resChunkCount, setResChunkCount] = useState(0);
  const [blobNum, setBlobNum] = useState([]);
  const [uploadState, setUploadState] = useState(false);
  const [mergedAssets, setMergedAssets] = useState(null);
  const [vidDimension, setVidDimension] = useState({ width: 1280, height: 720});
  const [isMute, setIsMute] = useState(false);
  const [isPause, setIsPause] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isEndRecordRequest , setIsEndRecordRequest] = useState(false);
  // eslint-disable-next-line
  const [_ , setIsChunkReportDone] = useState(false);

  const userId = 'user_id_01';

  const codecs = isFirefox ? 'video/webm;codecs="vp8,opus"' : 'video/webm;codecs="vp9,opus"';

  let countBolb = null;
  let fileName = null;
  let countStatus = null;
  const startAfterTime = splashCount * 1000;
  isEndRecordDetaSend.current = isEndRecordRequest;
  const isLinkButtonShow = isUserLoggedIn && isRegistered;

  // ui state
  const [isModalOpen, setIsModalOpen] = useState(false);

  const constraintsObj = useRef(null);

  constraintsObj.current = {
    audio: {
      deviceId: audioValue,
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100,
    },
    video : {
      deviceId: videoValue,
      width: { ideal: 1280, max: 1280 },
      height: { ideal: 720, max: 720 },
    },
  }


  const onChangeAudio = (e) => {
    setAudioValue(e.target.value);

    let config = {
      audio: {
        ...constraintsObj.current.audio,
        deviceId: e.target.value ? { exact: e.target.value } : undefined
      }
    }

    Object.assign(constraintsObj.current, config);
    window.localStorage.setItem('constraints', JSON.stringify(constraintsObj.current));
  }

  const onChangeVideo = (e) => {
    setVideoValue(e.target.value);

    let config = {
      video : {
        ...constraintsObj.current.video,
        deviceId: e.target.value ? { exact: e.target.value } : undefined,
      }
    }

    Object.assign(constraintsObj.current, config);
    window.localStorage.setItem('constraints', JSON.stringify(constraintsObj.current));
  }

  const toggleSettings = () => {
    setIsModalOpen(!isModalOpen);
  }

  const onClickBack = () => {

    if (recorderStream.current) {
      // recorderStream.current.getTracks().forEach((track) => console.log(track));
      recorderStream.current.getTracks().forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }
    setStartCapture(false);
    navigate('/mw-recorder-selection');
  }

  // safari not returning any response here,
  // 401 (Unauthorized)
  const onClickStart = () => {
    axios.post(config.apiUrls.createRecordingSession, {}).then((res) => {
      // check if service is available
      if (res.data.settings.serviceAvailable && (res.status === 200 || res.status === 201)) {
        audioBitsPerSecond.current = res.data.settings.audioBitsPerSecond;
        rDuration.current = res.data.settings.maxRecordingTime;
        chunkSplit.current = res.data.settings.postChunkSize;
        mediaPostUrl.current = res.data.settings.postUrl;
        chunkReportUrl.current = res.data.settings.recordingSessionChunkReportUrl;
        videoBitsPerSecond.current = res.data.settings.videoBitsPerSecond;
        sessionRemoveUrl.current = res.data.settings.recordingSessionRemoveUrl;
        sessionEditUrl.current = res.data.settings.recordingSessionEditTargetUrl;
        sessionTargetUrl.current = res.data.settings.recordingSessionFinishedTargetUrl;
        startRecording();
      } else {
        // if service is not available
        // notAvailableCallBack();
      }
    }).catch((err) => {
      // err.name === 'AxiosError' && notAvailableCallBack();
    });
  }

  const setupStream = useCallback(async () => {
    let constraints;

    if (videoValue || audioValue || vidDimension) {
      constraints =  JSON.parse(window.localStorage.getItem('constraints'));
    }

    // console.log('constraints', constraints);

    if (constraints !== null) {
      return navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        recorderStream.current = stream;
        if (videoFeedRef.current) {
          videoFeedRef.current.srcObject = stream;
        }
      }).catch((error) => console.warn(error));
    } else {
      return navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
        recorderStream.current = stream;
        if (videoFeedRef.current) {
          videoFeedRef.current.srcObject = stream;
        }
      }).catch((error) => console.warn(error));
    }

  }, [videoValue, audioValue, vidDimension]);

  function camRecording() {
    if (recorderStream.current) {
      startCountDown();

      const timeOut = setTimeout(() => {
        setStartCapture(true);
        // setShowTimeToast(true);

        const options = {
          // mimeType: 'video/webm;codecs="vp8,opus"', // firefox
          //mimeType: 'video/webm;codecs="vp9,opus"', // chrome
          mimeType: codecs,
          videoBitsPerSecond: videoBitsPerSecond.current, // See https://developers.google.com/media/vp9/settings/vod#recommended_settings
          audioBitsPerSecond: audioBitsPerSecond.current,
        }

        const cSplit = chunkSplit.current * 1000;
        countBolb = 0;
        fileName = '';
        setBlobNum([]);
        countStatus = 0;
        chunksLog.current = [];
        setIsChunkReportDone(false);
        setIsEndRecordRequest(false);

        sessionId.current = Date.now().toString(36) + Math.random().toString(36).substring(2);
        recordId.current = Date.now().toString(31) + Math.random().toString(31).substring(2);
        recordStartTime.current = new Date(Date.now()).toISOString();  // start Timestamp of recoding;

        mediaRecorder.current = new MediaRecorder(recorderStream.current, options);
        console.log(`MediaRecorder started with ${mediaRecorder.current.mimeType}`);
        mediaRecorder.current.ondataavailable = handleDataAvailable;
        mediaRecorder.current.onstop = handleStop;
        mediaRecorder.current.start(cSplit);

        startTimeLimit(rDuration.current);
        clearTimeout(timeOut);

      }, startAfterTime);
    }
  }

  const startTimeCount = useCallback((seconds = rDuration.current) => {
    intervalRef.current = setInterval(() => {
      const newCounter = seconds--;
      if (newCounter >= 0) {
        setRemaining((s) => s + 1);
      } else {
        clearInterval(intervalRef.current);
        setRemaining(0)
      }
    }, 1000);
  }, []);

  const startTimeLimit = (in_second) => {
    timeoutId.current = setTimeout(() => {
      stopRecording();
    }, in_second * 1000);
  }

  const startCountDown = () => {
    setBeforeStart(true);

    const interval = setInterval(() => {
      setSplashCount((prev) => {
        if ( prev > 0) {
          return prev - 1;
        } else {
          clearInterval(interval);
        }
      })
    }, 1000);
  }

  const toggleMute = () => {
    setIsMute(!isMute);

    const audioStream = recorderStream.current;

    if (audioStream) {
      const audioTrack = audioStream.getTracks().find((track) => track.kind === 'audio');

      if (audioTrack.enabled) {
        audioTrack.enabled = false;
        console.log('=> audio disabled');
      } else {
        audioTrack.enabled = true;
        console.log('=> audio enabled');
      }
    }
  }

  const togglePause = () => {
    setIsPause(!isPause);

    const recorder = mediaRecorder.current;
    if (recorder) {
      if (recorder.state === 'recording') {
        recorder.pause();
        setResume(remaining);
        clearInterval(intervalRef.current);
        clearTimeout(timeoutId.current);
        console.log('=> time counting paused');

      } else if (recorder.state === 'paused') {
        recorder.resume();
        startTimeCount((rDuration.current - resume) - 1);
        startTimeLimit((rDuration.current - resume) + 1);
        setResume(0);
        console.log('=> time counting pasue resumed');
      }
    }
  }

  function startRecording () {
    camRecording();
  }

  function stopRecording () {
    mediaRecorder.current.stop();
  }

  function handleStop () {
    recordEndTime.current = new Date(Date.now()).toISOString();
    recorderStream.current.getTracks().forEach((track) => track.stop());

    setRecordDone(true);
    setStartCapture(false);
    setBeforeStart(false);
    setSplashCount(3);

    setResume(0);
    setRemaining(0)
    clearInterval(intervalRef.current);
    clearTimeout(timeoutId.current);
  }

  function handleDataAvailable (e) {
    if (e.data && e.data.size > 0) {
      // console.log('blob', e.data);
      setChunkCount((cc) => cc + 1);

      countBolb = countBolb + 1;
      setBlobNum((state) => [...state, countBolb]);
      fileName = `${countBolb}.webm`;

      let logData = {
        dateTimeStart: new Date(Date.now()).toISOString(),
        dateTimeStop: '',
        video: fileName,
        size: e.data.size,
        responseStatus: '',
      }

      chunksLog.current.push(logData);

      let formData = new FormData();
      formData.append('sessionId', sessionId.current);
      formData.append('recordingId', recordId.current);
      formData.append('userId', userId);
      formData.append('recodingType', recodingType.current);
      formData.append('video', fileName);
      formData.append(`video-blob`, e.data);

      axios.post(mediaPostUrl.current, formData).then((res) => {

        if (res.data) {
          countStatus = countStatus + 1;
          fileName = `${countStatus}.webm`;
          const chunkObj = chunksLog.current.find((elem) => elem.video === fileName);

          Object.assign(chunkObj, {
            dateTimeStop: new Date(Date.now()).toISOString(),
            responseStatus: res.data.status,
          });
        }

        if (res.data.status === 200) {
          if (res.data.preview) {
            firstPoster.current = res.data.preview
            if (firstPoster) {
              videoPreviewRef.current.poster = firstPoster.current;
            }
          }
          console.log('upload-ended');
          setResChunkCount((rcc) => rcc + 1);
        }
      })
      .catch((err) => {
        console.warn(err);
      })
    }
  }

  const resetVideoRef = () => {
    videoPreviewRef.current.poster = '';
    videoPreviewRef.current.src = '';
    videoPreviewRef.current.load();
    firstPoster.current = 'poster_url';
  }

  const resetState = () => {
    setRecordDone(false);
    setChunkCount(0);
    setResChunkCount(0);
    setMergedAssets(null);
    setBlobNum([]);
    setUploadState(false);
    setIsMute(false);
    setIsPause(false);
    setResume(0);
    setRemaining(0)
  }

  const recordAgain = async () => {
    resetState();
    resetVideoRef();
    clearInterval(intervalRef.current);
    await setupStream();
  }

  // wait for
  function waitFor (condiFunc) {
    const poll = (resolve) => {
      if (condiFunc()) {
        resolve();
      } else {
        setTimeout(() => poll(resolve), 500);
      }
    }
    return new Promise(poll);
  }


  const deleteAndBack = () => {
    stopRecording();
    setIsDeleting(true);

    waitFor(() => isEndRecordDetaSend.current === true).then(() => {
      axios.delete(sessionRemoveUrl.current).then((res) => {
        if (res.statusText === 'OK') {
          setIsDeleting(false);
          resetState();
          resetVideoRef();
          navigate('/');
        }
      });
    });
  }

  const deleteVideo = () => {
    setIsDeleting(true);
    axios.delete(sessionRemoveUrl.current).then((res) => {
      if (res.status === 200) {
        setIsDeleting(false);
        resetState();
        resetVideoRef();
        (async () => setupStream())();
      }
    });
  }

  const stopAndRecordAgain = () => {
    stopRecording();
    setIsDeleting(true);

    waitFor(() => isEndRecordDetaSend.current === true).then(() => {
      axios.delete(sessionRemoveUrl.current).then((res) => {
        if (res.statusText === 'OK') {
          setIsDeleting(false);
          recordAgain();
        }
      });
    });
  }

  const repeatRecording = () => {
    setIsDeleting(true);

    const timeOut = setTimeout(() => {
      setIsDeleting(false);
      recordAgain();
      clearTimeout(timeOut);
    }, 400);
  }

  const copyLink = () => {
    copyTextToClipboard(sessionTargetUrl.current).then(() => {
      setIsCopied(true);
      const timeOut = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(timeOut);
      }, 1500);
    })
    .catch((err) => console.warn(err));
  };

  // recording last call back
  const endRecordReq = useCallback(() => {
    const totalBlobNum = blobNum[blobNum.length - 1];

    if ( chunkCount !== 0 && chunkCount === resChunkCount) {
      if (totalBlobNum <= resChunkCount) {
        const formData = new FormData();
        formData.append('sessionId', sessionId.current);
        formData.append('userId', userId);
        formData.append('recordingId', recordId.current);
        formData.append('recordingDone', true);

        axios.post(mediaPostUrl.current, formData).then((res) => {
          if (res.status === 200) {
            setIsEndRecordRequest(true);

            if (res.data) {
              setMergedAssets(res.data);
              downLoadLink.current = res.data.previewVideo;
              videoPreviewRef.current.src = res.data.previewVideo;
            }
            console.log('Recording Successfully Done! Preview is ready.');
          }
        }).catch( (err) => {console.log(err)})
      }
    }

  },[chunkCount, resChunkCount, blobNum]);

  // chunk repots
  const chunkReportReq = useCallback(() => {
    const formData = new FormData();
    formData.append('recodingType', recodingType.current);
    formData.append('dateTimeStart', recordStartTime.current);
    formData.append('dateTimeStop', recordEndTime.current);
    formData.append('chunks', JSON.stringify(chunksLog.current, null, 2));

    axios.put(chunkReportUrl.current, formData).then((res) => {
      if (res.status === 201) {
        setIsChunkReportDone(true);
        console.log('Chunks report has been sent successfully');
      }
    }).catch((err) => console.warn(err))

  },[]);

  // start time counting when record start
  useEffect(() => {
    if (startCapture) {
      startTimeCount(rDuration.current);
    }
    return () => {
      clearInterval(intervalRef.current);
    }
  }, [startCapture, startTimeCount]);


  useEffect(() => {
    if (recordDone) {
      endRecordReq();
    }
  }, [endRecordReq, recordDone]);

  // call when isChunkReportDone true
  useEffect(() => {
    if (isEndRecordRequest) {
      chunkReportReq();
    }
  }, [chunkReportReq, isEndRecordRequest]);


  // setup
  useEffect(() => {
    (async () => setupStream())();
  }, [setupStream]);


  useEffect(() => {
    if ( chunkCount !== 0 && chunkCount === resChunkCount && recordDone) {
      setUploadState(true);
    } else {
      setUploadState(false)
    }
  }, [chunkCount, resChunkCount, recordDone]);

  // download button
  useEffect(() => {
    if (downLoadRef.current) {
      downLoadRef.current.href = downLoadLink.current;
      downLoadRef.current.download = 'cam_vid.webm';
    }
  }, [mergedAssets, uploadState, downLoadRef]);

  // clean up stram when done
  useEffect(() => {
    if (!startCapture) {
      if (recorderStream.current) {
        recorderStream.current.getTracks().forEach((track) => track.stop());
      }
    }
  }, [startCapture]);

  useEffect(() => {
    if (initalState) {
      setIsUserLoggedIn(initalState.userIsLoggedIn);
      setIsRegistered(initalState.userIsRegistered);
    }
  }, [initalState]);

  return (
    <Container>
      <Header
        isRecording={startCapture}
        hideBackButton={isModalOpen}
        onClickBack={onClickBack}
        onClickClose={() => navigate('/')}
        remaining={remaining}
        totalTime={rDuration.current && rDuration.current}
      />

      {/* stream and video elem */}
      {!recordDone && (
        <FeedContainer
          ref={videoFeedRef}
          isModalOpen={isModalOpen}
          isRecording={startCapture}
          onClickStart={onClickStart}
          onClickSetting={toggleSettings}
        />
      )}

      {/* footer during recording */}
      {!recordDone && startCapture && (
        <RecordingFooter
          isPause={isPause}
          isMute={isMute}
          onClickToggleMute={toggleMute}
          onClickTogglePause={togglePause}
          onClickStopAndRecordAgain={stopAndRecordAgain}
          onClickDeleteAndBack={deleteAndBack}
          onClickStopRecording={stopRecording}
        />
      )}

      <PreviewContainer
        isVisible={recordDone}
        ref={videoPreviewRef}
      />

      {/* footer after recording */}
      {recordDone && (
        <PreviewFooter
          ref={downLoadRef}
          isLinkButtonShow={isLinkButtonShow}
          chunkCount={chunkCount}
          resChunkCount={resChunkCount}
          mergedAssets={mergedAssets}
          uploadState={uploadState}
          sesTargetUrl={sessionTargetUrl.current}
          sesEditUrl={sessionEditUrl.current}
          onClickDeleteVideo={deleteVideo}
          onClickRecordAgain={repeatRecording}
          onClickCopyLink={copyLink}
        />
      )}

      {beforeStart && splashCount > 0 && (
        <CountAnim boundary number={splashCount} />
      )}

      <CamSettings
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        videoValue={videoValue}
        audioValue={audioValue}
        videoOptions={videoOptions}
        audioOptions={audioOptions}
        onChangeVideo={onChangeVideo}
        onChangeAudio={onChangeAudio}
        setVidDimension={setVidDimension}
      />

      {isDeleting && (
        <div className='mercurius-overlay absolute flex items-center justify-center'>
          <LoaderJelly
            size={50}
            color='#fff'
          />
        </div>
      )}

      {isCopied && (
        <div className='mercurius-overlay absolute flex items-center justify-center'>
          <p style={{ margin: 0, color: '#fff', fontSize: 17 }}>Video Link copied to clipboard</p>
        </div>
      )}

      {recordDone && !isDeleting && (
        <DoneMessage
          isHidden={mergedAssets && uploadState}
          message="Awesome video 😎 - We need a few more seconds, we're almost done..."
        />
      )}
    </Container>
  );
}
