import { useState, useEffect } from 'react';


export default function useDeviceList () {
  const [audioValue, setAudioValue] = useState();
  const [videoValue, setVideoValue] = useState();
  const [videoOptions, setVideoOptions] = useState([]);
  const [audioOptions, setAudioOptions] = useState([]);

  useEffect(() => {
    async function enableStream() {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true, video: true }); // it need only for firefox, otherwise setting options not showing
        let devices = await navigator.mediaDevices.enumerateDevices();
        const videoInput = devices.filter(({ kind }) => kind === "videoinput")[0].deviceId;
        const audioInput = devices.filter(({ kind }) => kind === "audioinput")[0].deviceId
        setVideoValue(videoInput);
        setAudioValue(audioInput);
        setVideoOptions(devices.filter(item => item.kind === 'videoinput').map(({ deviceId, label }) => {
          return {deviceId, label}
        }));
        setAudioOptions(devices.filter(item => item.kind === 'audioinput').map(({ deviceId, label }) => {
          return {deviceId, label}
        }));

      } catch(err) {
        alert(err.message)
      }
    }
    enableStream();
  }, []);

  return {
    audioValue,
    videoValue,
    videoOptions,
    audioOptions,
    setAudioValue,
    setVideoValue,
  };
};
