import React from 'react';
import styled from 'styled-components';

export default function BackButton({ onClick = () => {}, }) {

  return (
    <BackBtn
      onClick={onClick}
    >
      <svg height="24" width="24" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(3 6)">
          <path d="m4.499.497-3.999 4.002 4 4.001"/>
          <path d="m13.5 4.5h-13"/>
        </g>
      </svg>
      <div style={{ paddingLeft: 4 }}>Back</div>
    </BackBtn>
  )
}

const BackBtn = styled.button.attrs({ className: 'wbtn wbtn-center wbtn-lucid'})`
  color: var(--gray-1);
  font-weight: var(--body-font-weight);
  font-size: 15px;
  transition: color .15s linear;

  svg {
    transition: stroke .15s linear;
  }

  &:hover {
    color: white;
  }
`
