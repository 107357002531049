import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { getSettings } from '../services/slices/rootSlice';

export default function Welcome (props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { initId } = props.options;

  const navigateHandlar = () => {
    navigate('/mw-recorder-selection');
  }

  useEffect(() => {
    dispatch(getSettings(initId));
  }, [dispatch, initId]);

  return (
    <StartBox>
      <BtnStart onClick={navigateHandlar}>Start</BtnStart>
    </StartBox>
  );
}

const StartBox = styled.div.attrs({ className: 'flex items-center justify-center'})`
  width: 100%;
  max-width: 600px;
  padding: 30px;
  min-height: 220px;
  border-radius: var(--radius-small);
  background-color: #fff;
  box-shadow: var(--elevation-4);
`


const BtnStart = styled.button.attrs({ className: 'wbtn wbtn-center btn-transition'})`
  font-size: 17px;
  color: white;
  height: 44px;
  padding: 0 40px;
  border-radius: var(--radius-small);
  border: 1p solid var(--brand-color);
  background-color: var(--brand-color);

  &:focus,
  &:active,
  &:hover {
    color: white;
    border-color: var(--brand-dark-color);
    background-color: var(--brand-dark-color);
  }
`
