import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import LoaderJelly from './LoaderJelly';

export default function DoneMessage({
  isHidden,
  message = '',
}) {
  return (
    <Done isHidden={isHidden}>
      <LoaderJelly inline size={40} color='#fff' />
      <Text>{message}</Text>
    </Done>
  )
}

const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Done = styled.div.attrs({ className: 'absolute top-0 left-0 flex-column items-center justify-center'})`
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--radius-medium);
  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: var(--black-transparent-1, hsla(217, 33%, 17%, 0.7));
  display: flex;

  ${({ isHidden }) => isHidden && css `display: none;`}
`

const Text = styled.div`
  margin: 16px 0 0 0;
  line-height: 1.2;
  font-weight: 500;
  font-size: 30px;
  max-width: 450px;
  text-align: center;
  color: #fff;
`
