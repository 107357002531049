const alertStyle = document.createElement('style');

  alertStyle.dataset.widgetStyle = "mercurius"

alertStyle.innerHTML = `
  .mercurius-alert-dialog-overlay {
    position: fixed;inset: 0;animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    background-color: hsla(217, 33%, 17%, 0.6);
    backdrop-filter: blur(2px);
  }

  .mercurius-alert-dialog-content {
    width: 90vw;
    max-width: 500px;
    max-height: 85vh;
    padding: 25px;
    border-radius: 7px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .mercurius-alert-dialog-content.light {
    max-height: 70vh;
    padding: 15px;
    text-align: center;
  }

  .mercurius-alert-dialog-content:focus {
    outline: none;
  }

  .mercurius-alert-dialog-title {
    line-height: 1.2;
    color: #475569;
    font-size: 19px;
    font-weight: 500;
    margin: 0 0 5px 0;
  }

  .mercurius-alert-dialog-description {
    font-weight: 300;
    color: #334155;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 20px 0;
  }

  .mercurius-utils-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 34px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    transition: all 0.15s ease;
  }

  .mercurius-cancel:hover{
    background-color: #f8fafc;
    border-color: #94a3b8;
  }

  .mercurius-delete {
    color: #e11d48;
    border-color: #f43f5e;
  }

  .mercurius-delete:hover {
    color: white;
    background-color: #f43f5e;
  }

  .mercurius-cancel:focus,
  .mercurius-delete:focus {
    box-shadow: 0 0 0 2px hsla(217, 33%, 17%, 0.5);
  }

  .mercurius-dropdown-menu-content {
    min-width: 220px;
    background-color: white;
    border-radius: 6px;
    padding: 6px 8px;
    box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }

  .mercurius-dropdown-menu-content[data-side='top']{
    animation-name: slideDownAndFade;
  }

  .mercurius-dropdown-menu-content[data-side='right']{
    animation-name: slideLeftAndFade;
  }

  .mercurius-dropdown-menu-content[data-side='bottom']{
    animation-name: slideUpAndFade;
  }

  .mercurius-dropdown-menu-content[data-side='left']{
    animation-name: slideRightAndFade;
  }

  .mercurius-menu-item {
    position: relative;
    line-height: 1;
    color: #334155;
    padding: 1px 0;
    user-select: none;
    outline: none;
    display: flex;
    align-items: center;
  }

  .mercurius-menu-item.quality-item {
    line-height: 1.3;
    font-weight: 300;
    font-size: 15px;
    padding: 5px 10px;
    cursor: pointer;
  }

  .mercurius-menu-item[data-disabled] {
    color: #cbd5e1;
    pointer-events: none;
  }

  .mercurius-menu-item[data-disabled] span{
    color: #cbd5e1;
  }

  .mercurius-menu-item[data-highlighted]{
    background-color: #009EE2;
    color: white;
  }

  .mercurius-menu-item-btn {
    display: inline-flex;
    align-items: center;
    color: #475569;
    cursor: pointer;
    padding: 0 4px;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    height: 32px;
    background-color: #fff;
    border: 1px solid #fff;
    transition: all 0.15s ease;
    width: 100%;
  }

  .mercurius-menu-item-btn:hover {
    color: #1e293b;
    background-color: #e2e8f0;
    border: 1px solid #e2e8f0;
  }

  .mercurius-menu-separator {
    height: 1px;
    background-color: #cbd5e1;
    margin: 4px 5px;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`.replace(/^\s+|\n/gm, '');

export default alertStyle;
