import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function LoaderJelly({ color, size, inline }) {
  return (
    <Jelly color={color} size={size} inline={inline}>
      <svg width='0' height='0' className='jelly-maker'>
        <defs>
          <filter id='uib-jelly-ooze'>
            <feGaussianBlur
              in='SourceGraphic'
              stdDeviation='6.25'
              result='blur'
            />
            <feColorMatrix
              in='blur'
              mode='matrix'
              values='1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7'
              result='ooze'
            />
            <feBlend in='SourceGraphic' in2='ooze' />
          </filter>
        </defs>
      </svg>
    </Jelly>
  )
}

const rotate = keyframes`
  0%,
  49.999%,
  100% {
    transform: none;
  }

  50%,
  99.999% {
    transform: rotate(90deg);
  }
`

const shiftLeft = keyframes`
  0%,
  100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(-75%);
  }
`

const shiftRight = keyframes`
  0%,
  100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(75%);
  }
`

const Jelly = styled.div.attrs({ className: 'jelly relative' })`
  --uib-size: ${({ size }) => size ? `${size}px` : '50px' };
  --uib-speed: .9s;
  --uib-color: ${({ color }) => color ? `${color}` : 'black' };
  display: ${({ inline }) => inline ? 'inline-block' : 'block' };
  height: calc(var(--uib-size) / 2);
  width: var(--uib-size);
  filter: url('#uib-jelly-ooze');
  animation: ${rotate} calc(var(--uib-speed) * 2) linear infinite;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 25%;
    width: 50%;
    height: 100%;
    background: var(--uib-color);
    border-radius: 100%;
  }

  &::before {
    animation: ${shiftLeft} var(--uib-speed) ease infinite;
  }

  &::after {
    animation: ${shiftRight} var(--uib-speed) ease infinite;
  }

  .jelly-maker {
    width: 0;
    height: 0;
    position: absolute;
  }
`
