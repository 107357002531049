import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Routes, MemoryRouter } from 'react-router-dom';
import reactToWebComponent from 'react-to-webcomponent';
import { StyleSheetManager } from 'styled-components';
import store from './store';

import AppRoot from './layout/AppRoot';
import Welcome from './Pages/Welcome';
import Choose from './Pages/Choose';

import CameraRecording from './recorder/CameraRecording';
import ScreenRecording from './recorder/ScreenRecording';

import GlobalStyles from './GlobalStyles';
let ref_of_app_host;


function AppWrapper (props) {
  let firefox = navigator.userAgent.indexOf("Firefox") > -1;
  let safari = navigator.userAgent.indexOf("Safari") > -1
      && navigator.userAgent.indexOf("Chrome") === -1;

  const options = Object.assign({}, {...props, isFirefox: firefox, isSafari: safari });

  return (
    <StyleSheetManager target={ref_of_app_host}>
      <Provider store={store}>
        <AppRoot options={options}>
          <MemoryRouter>
            <Routes>
              <Route path='/' element={<Welcome options={options} />} />
              <Route path='/mw-recorder-selection' element={<Choose />} />
              <Route path='/mw-camera-recording' element={<CameraRecording options={options} />} />
              <Route path='/mw-screen-recording' element={<ScreenRecording options={options} />} />
            </Routes>
          </MemoryRouter>
        </AppRoot>
        <GlobalStyles />
      </Provider>
    </StyleSheetManager>
  )
}


const defineApp = reactToWebComponent(AppWrapper, React, ReactDOM, {
  shadow: 'open',
  props: {
    initId: String,
    brandColor: String,
    brandDarkColor: String,
    primaryColor: String,
    primaryDarkColor: String,
    accentColor: String,
    // numProp: Number,
    // trueProp: Boolean,
    // falseProp: Boolean,
    // objProp: Object,
  },
});
customElements.define('mercurius-web-recorder', defineApp);


const appHost = document.querySelector('mercurius-web-recorder');
ref_of_app_host = appHost.shadowRoot;


// this way we don't need to write tag manually
// var appendApp = new MainApp()
// document.body.appendChild(appendApp)
