import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function CountAnim({
  number,
  boundary = false,
}) {
  return (
    <Wrap boundary={boundary}>
      <Ripples>
        <div className='ripple-dot'>
          <span className='num relative flex items-center justify-center'>{number}</span>
        </div>
      </Ripples>
    </Wrap>
  )
}


const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`

const Wrap = styled.div.attrs({ className: 'fixed top-0 right-0 flex items-center justify-center' })`
  width: 100%;
  height: 100%;
  z-index: 120;
  backdrop-filter: blur(4px);
  background: rgba(248, 248, 248, 0.45);

  ${({ boundary }) => boundary && `
    border-radius: 5px;
    position: absolute !important;
  `}
`

const Ripples = styled.div.attrs({ className: 'count-anim relative flex items-center justify-center' })`
  --uib-size: 110px;
  --uib-speed: 3s;
  --uib-color: var(--gray-9, black);
  height: var(--uib-size);
  width: var(--uib-size);

  .num {
    color: white;
    font-size: 35px;
    height: calc(var(--uib-size) / 2);
    width: calc(var(--uib-size) / 2);
    border-radius: 50%;
    background-color: var(--slate-700, black);
    z-index: 10;
  }

  &::before,
  &::after,
  .ripple-dot::before,
  .ripple-dot::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: var(--uib-color);
    animation: ${pulse} var(--uib-speed) linear infinite;
    transform: scale(0);
    opacity: 0;
  }

  &::after {
    animation-delay: calc(var(--uib-speed) / -4);
  }

  .ripple-dot::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }

  .ripple-dot::after {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
`
