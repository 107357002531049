import React from 'react';

export default function IconMute({
  size = '24px',
  currentColor = 'currentColor',
  svgClassName,
  svgStyle,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={svgClassName}
      stroke={currentColor}
      style={{
        '--size': size,
        '--vb-height': '24px',
        '--vb-width': '24px',
        width: 'var(--size)',
        height: 'calc((var(--vb-height) / var(--vb-width)) * var(--size))',
        pointerEvents: 'none',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <rect x="9" y="2" width="6" height="11" rx="3" />
      <path d="M5 10a7 7 0 0 0 14 0" />
      <line x1="8" y1="21" x2="16" y2="21" />
      <line x1="12" y1="17" x2="12" y2="21" />
    </svg>
  )
}
