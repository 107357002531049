import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


export default function Choose() {
  const navigate = useNavigate();

  return (
    <Box>
      <LargeBtn
        title="Camera Recording"
        onClick={() => navigate('/mw-camera-recording')}
      >
        <svg xmlns="http://www.w3.org/2000/svg"  width="48" height="48" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <circle cx="12" cy="10" r="7" />
          <circle cx="12" cy="10" r="3" />
          <path d="M8 16l-2.091 3.486a1 1 0 0 0 .857 1.514h10.468a1 1 0 0 0 .857 -1.514l-2.091 -3.486" />
        </svg>
        <div className='text cam'>Record Camera</div>
      </LargeBtn>

      <LargeBtn
        title="Screen Recording"
        onClick={() => navigate('/mw-screen-recording')}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="currentColor">
          <path d="M7 37h34V19.65H26.2V11H7v26Zm0 3q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3V11v26Z"/>
        </svg>
        <div className='text'>Record Screen</div>
      </LargeBtn>
    </Box>
  )
}


const Box = styled.div.attrs({ className: 'flex items-center justify-between'})`
  width: 100%;
  max-width: 600px;
  padding: 30px;
  border-radius: var(--radius-small);
  gap: 30px;
  background-color: #fff;
  box-shadow: var(--elevation-4);
`

const LargeBtn = styled.button.attrs({ className: 'flex flex-column items-center justify-center btn-transition'})`
  flex: 1 1 50%;
  cursor: pointer;
  color: #fff;
  height: 160px;
  border-radius: var(--radius-small);
  padding: 40px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);


  .text {
    color: inherit;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-top: 10px;

    &.cam {
      padding-top: 11px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    outline: none;
    border: 1px solid var(--primary-dark-color);
    background-color: var(--primary-dark-color);
  }
`
