import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../axios';
import config from '../config';

export const getSettings = createAsyncThunk('root/settings', async (initId) => {
  // console.log('', initId);
  try {
    const response = await axios.get(config.apiUrls.getSessionInfo);
    return {...response.data};
  } catch (err) {
    return err.message;
  }
});

const initialState = {
  settings: {},
  status: 'idle', // 'pending' | 'succeeded' | 'failed'
  colors: {},
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setColor: (state, action) => {
      state.colors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.pending, (state) => {
      Object.assign(state, { status: 'loading'});
    });
    builder.addCase(getSettings.fulfilled, (state, action) => {
      const { settings } = action.payload;
      Object.assign(state, { status: 'succeeded', settings });
    });
    builder.addCase(getSettings.rejected, (state) => {
      Object.assign(state, { status: 'failed'});
    });
  }
});


export const getSettingsState = (state) => state.root.settings;
export const getColorState = (state) => state.root.colors;

export const { setColor } = rootSlice.actions;

export default rootSlice.reducer;
