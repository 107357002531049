import React, { forwardRef } from 'react';
import styled from 'styled-components';
import SettingButton from './SettingButton';

const FeedContainer = forwardRef(({
  isRecording,
  isModalOpen,
  onClickSetting = () => {},
  onClickStart = () => {},
}, ref) => {
  return (
    <>
      <Container>
        <Elem>
          <video
            ref={ref}
            muted
            autoPlay
          >
          </video>
        </Elem>

        {!isRecording && !isModalOpen && (
          <Bc>
            <StartBtn onClick={onClickStart}>Start Record</StartBtn>
          </Bc>
        )}
      </Container>

      {!isRecording && (
        <Footer>
          <div className='flex-auto flex items-center justify-end'>
          </div>
          <SettingButton onClick={onClickSetting} />
        </Footer>
      )}
    </>
  )
});

export default FeedContainer;


const Container = styled.div.attrs({ className: 'relative'})`
  width: 100%;
`

const Elem = styled.div.attrs({ className: 'aspect-ratio'})`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background-color: var(--gray-6);

  > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Bc = styled.div.attrs({ className: 'absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center'})`
  width: 100%;
  height: 100%;
  background-color: rgba(87, 86, 93, .05);
  transition: background-color 0.15s ease;

  &:hover {
    background-color: rgba(87, 86, 93, .20);
  }

  &:hover .start-btn {
    opacity: 1;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.9);
    background-color: var(--brand-color);
  }
`

const StartBtn = styled.button.attrs({ className: 'start-btn inline-flex items-center justify-center btn-transition'})`
  cursor: pointer;
  line-height: 1;
  padding: 0 20px;
  height: 52px;
  border-radius: var(--radius-large);
  color: white;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid rgba(255, 255, 255, 0.6);
  background-color: rgba(87, 86, 93, .6);
  opacity: 0.6;

  &:hover {
    border-color: #fff;
    background-color: var(--brand-dark-color);
  }
`

const Footer = styled.div.attrs({ className: 'fn-footer relative flex items-center' })`
  height: var(--bar-height);
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  background-color: var(--bar-bg);

  .flex-auto {
    padding: 0 12px;
  }
`
