import React, { forwardRef } from 'react';
import styled from 'styled-components';

import LoaderJelly from './LoaderJelly';

const FeedContainerLight = forwardRef(({
  isRecording,
}, ref) => {
  return (
    <>
      <Container>
        <Elem>
          <video
            ref={ref}
            muted
            autoPlay
          >
          </video>
        </Elem>

        {!isRecording && (
          <>
            <Ovarlay>
              <LoaderJelly inline size={40} color='#fff' />
            </Ovarlay>
            <Footer>
            </Footer>
          </>
        )}
      </Container>
    </>
  )
});

export default FeedContainerLight;


const Container = styled.div.attrs({ className: 'relative'})`
  width: 100%;
`

const Elem = styled.div.attrs({ className: 'aspect-ratio'})`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background-color: var(--brand-color);

  > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Ovarlay = styled.div.attrs({ className: 'absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center'})`
  width: 100%;
  height: 100%;
  background-color: rgba(87, 86, 93, .05);
`

const Footer = styled.div.attrs({ className: 'fn-footer relative flex items-center' })`
  height: var(--bar-height);
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  background-color: var(--bar-bg);

  .flex-auto {
    padding: 0 12px;
  }
`
