import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

const PreviewContainer = forwardRef(({ isVisible }, ref) => {

  return (
    <Preview isVisible={isVisible}>
      <AspectRatio>
        <video
          ref={ref}
          autoPlay
          controls
        >
        </video>
      </AspectRatio>
    </Preview>
  )
});

export default PreviewContainer;


const Preview = styled.div.attrs({ className: 'relative mercurius-preview-container'})`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;

  ${({ isVisible }) => isVisible && css `
    width: 100%;
    height: auto;
    opacity: 1;
  `}
`
const AspectRatio = styled.div.attrs({ className: 'mercurius-aspect-ratio'})`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background-color: var(--gray-6);

  > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
