import React, { forwardRef } from 'react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import IconDownload from '../icons/IconDownload';
import IconTrash from '../icons/IconTrash';
import IconReload from '../icons/IconReload';
import IconChevron from '../icons/IconChevron';
import IconLink from '../icons/IconLink';
import LoaderJelly from './LoaderJelly';

const PreviewFooter = forwardRef(({
  isLinkButtonShow,
  chunkCount,
  resChunkCount,
  mergedAssets,
  uploadState,
  sesTargetUrl,
  sesEditUrl,
  onClickDeleteVideo = () => {},
  onClickRecordAgain = () => {},
  onClickCopyLink = () => {},
}, ref) => {
  return (
    <Footer>
      <div className='footer-part-start relative flex items-center justify-start'>
        {!(mergedAssets && uploadState) && (
          <div
            className='flex items-center justify-center'
            style={{
              height: 30,
              flexBasis: 130,
              flexShrink: 0,
              backgroundColor: '#daedf6',
              borderRadius: 4,
            }}
          >
            <LoaderJelly
              size={24}
              color='var(--brand-color)'
            />
          </div>
        )}

        <div
          className='items-center justify-start'
          style={{ display: (mergedAssets && uploadState) ? 'flex' : 'none', gap: 6 }}
        >
          <BtnAction
            as='a'
            ref={ref}
            // title={t('camR_btn_title_download_video')}
          >
            <IconDownload size={18} />
          </BtnAction>

          <AlertDialog.Root>
            <AlertDialog.Trigger asChild>
              <BtnAction
                // title={t('camR_btn_title_delete_video')}
              >
                <IconTrash size='17px' />
              </BtnAction>
            </AlertDialog.Trigger>

            <AlertDialog.Portal>
              <AlertDialog.Overlay className='mercurius-alert-dialog-overlay' />
              <AlertDialog.Content className='mercurius-alert-dialog-content'>
                <AlertDialog.Title className='mercurius-alert-dialog-title'>
                  {/* {t('camR_delete_video_heading')} */}
                  Are you absolutely sure?
                </AlertDialog.Title>
                <AlertDialog.Description className='mercurius-alert-dialog-description'>
                  {/* {t('camR_delete_video_description')} */}
                  This action cannot be undone. It will permanently delete your video you recorded moment ago.
                </AlertDialog.Description>

                <div style={{ display: 'flex', gap: 15, justifyContent: 'flex-end'}}>
                  <AlertDialog.Cancel asChild>
                    <button
                      className='mercurius-utils-btn mercurius-cancel'
                      // title={t('camR_btn_title_cancel_deletion')}
                    >
                      {/* {t('camR_btn_cancel_label')} */}
                      Cancel
                    </button>
                  </AlertDialog.Cancel>
                  <AlertDialog.Action asChild>
                    <button
                      className='mercurius-utils-btn mercurius-delete'
                      // title={t('camR_btn_title_do_deletion')}
                      onClick={onClickDeleteVideo}
                    >
                      {/* {t('camR_btn_yes_delete_label')} */}
                      Yes, delete it
                    </button>
                  </AlertDialog.Action>
                </div>
              </AlertDialog.Content>
            </AlertDialog.Portal>

          </AlertDialog.Root>

          <BtnAction
            onClick={onClickRecordAgain}
            // title={t('camR_btn_title_record_again')}
          >
            <IconReload size='17px' />
          </BtnAction>
        </div>

      </div>

      <div className='footer-part-end relative flex items-center justify-end'>
        {isLinkButtonShow && (
          <div className='relative inline-flex items-center' style={{ borderRadius: 4 }}>
            <BtnGetLink
              className={(mergedAssets && uploadState) ? 'active': null}
              onClick={onClickCopyLink}
              // title={t('camR_btn_title_copy_link')}
            >
              <div style={{ flexShrink: 0, width: 18, marginRight: 5 }}>
                {!mergedAssets && (<LoaderJelly inline size={15} color='var(--brand-color)' />)}
                {mergedAssets && uploadState && (<IconLink size={16} svgStyle={{ position: 'relative', top: 2 }} />)}
              </div>
              <span style={{ display: 'inline-block' }}>
                {/* {t('camR_btn_copy_link_label')} */}
                Copy link
              </span>
            </BtnGetLink>

            <DropdownMenu.Root>
              <DropdownMenu.Trigger asChild>
                <BtnLinkDrop className={(mergedAssets && uploadState) ? 'active': null}>
                  <IconChevron svgClassName='svg-icon' size={24} />
                </BtnLinkDrop>
              </DropdownMenu.Trigger>

              <DropdownMenu.Portal>
                <DropdownMenu.Content className='mercurius-dropdown-menu-content' side='top' align='right'>
                  <DropdownMenu.Item className='mercurius-menu-item'>
                    <button
                      onClick={onClickCopyLink}
                      className='mercurius-menu-item-btn'
                      // title={t('camR_btn_title_copy_link')}
                    >
                      {/* {t('camR_btn_title_copy_link')} */}
                      Copy video link to clipboard
                    </button>
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator className='mercurius-menu-separator' />
                  <DropdownMenu.Item className='mercurius-menu-item'>
                    <button
                      className='mercurius-menu-item-btn'
                      onClick={() => window.open(sesTargetUrl)}
                      // title={t('camR_btn_open_link_new_tab')}
                    >
                      {/* {t('camR_btn_open_link_new_tab')} */}
                      Open in a new tab
                    </button>
                  </DropdownMenu.Item>
                  <DropdownMenu.Arrow style={{fill: '#fff'}} />
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>

          </div>
        )}

        <BtnDone
          className={(mergedAssets && uploadState) ? 'active': null}
          onClick={() => window.open(sesEditUrl, '_self')}
          // title={t('camR_btn_title_done')}
        >
          {/* {t('camR_btn_title_done')} */}
          Done & Save Video
        </BtnDone>
      </div>
    </Footer>
  )
});

export default PreviewFooter;


const Footer = styled.div.attrs({ className: 'preview-footer relative flex items-center' })`
  height: var(--bar-height);
  border-bottom-left-radius: var(--radius-medium);
  border-bottom-right-radius: var(--radius-medium);
  background-color: var(--bar-bg);

  .footer-part-start {
    flex-basis: 145px;
    max-width: 145px;
    padding-left: 15px;
  }

  .footer-part-end {
    flex-basis: calc(100% - 145px);
    max-width: calc(100% - 145px);
    padding-right: 15px;
    gap: 7px;
  }
`

const ButtonStyle = styled.button.attrs({ type: 'button', className: 'wbtn wbtn-center btn-transition' })`
  font-size: 15px;
  padding: 6px 12px;
  border-radius: var(--radius-small);
  border: 1px solid hsla(253, 10%, 35%, 1);
  background-color: hsla(253, 10%, 65%, 1);

  svg.svg-icon {
    transition: fill .15s ease;
  }
`;


const BtnAction = styled.button.attrs({ type: 'button', className: 'wbtn wbtn-center btn-transition' })`
  font-size: 15px;
  color: var(--gray-6);
  padding: 7px 8px;
  width: 37px;
  height: 35px;
  border-radius: var(--radius-small);
  border: 1px solid #0185bd;
  background-color: #fff;

  &:focus,
  &:active,
  &:hover {
    border-color: #0185bd;
    background-color: #b2e6fd;
  }

  &.is-selected {
    color: #ff1347;
  }

  svg.svg-icon {
    transition: fill .15s ease;
  }
`;


const BtnGetLink = styled(ButtonStyle)`
  cursor: not-allowed;
  flex-shrink: 0;
  height: 35px;
  min-width: 114px;
  color: var(--gray-3);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #0185bd;
  background-color: #fff;

  &.active {
    cursor: pointer;
    color: var(--gray-6);

    &:focus,
    &:active,
    &:hover {
      border-color: #0185bd;
      background-color: #b2e6fd;
    }
  }
`

const BtnLinkDrop = styled(ButtonStyle)`
  padding: 6px 9px;
  flex-shrink: 0;
  height: 35px;
  color: var(--gray-3);
  cursor: not-allowed;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: #0185bd;
  background-color: #fff;

  &.active {
    color: var(--gray-6);
    cursor: pointer;

    &:focus,
    &:active,
    &:hover {
      border-color: #0185bd;
      background-color: #b2e6fd;
    }
  }

  &[data-state='open'] {
    svg {
      transform: rotate(180deg);
    }
  }
`

const BtnDone = styled(ButtonStyle)`
  cursor: not-allowed;
  padding: 6px 12px;
  flex-shrink: 0;
  height: 35px;
  color: var(--gray-3);
  border-color: #0185bd;
  background-color: #fff;


  &.active {
    cursor: pointer;
    color: var(--gray-6);

    &:focus,
    &:active,
    &:hover {
      border-color: #0185bd;
      background-color: #b2e6fd;
    }
  }
`
