import React from 'react';

export default function IconStop({
  size = 20,
  currentColor = 'currentColor',
  svgClassName,
  svgStyle,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 48 48"
      fill="none"
      className={svgClassName}
      style={{
        '--size': `calc(1px * ${size})`,
        '--vb-height': '48px',
        '--vb-width': '48px',
        width: 'var(--size)',
        height: 'calc((var(--vb-height) / var(--vb-width)) * var(--size))',
        pointerEvents: 'none',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={currentColor} d="M16.5 31.5h15v-15h-15ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"/>
    </svg>
  )
}
