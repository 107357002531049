import React from 'react';

export default function IconLink({
  size = 24,
  currentColor = 'currentColor',
  svgClassName,
  svgStyle,
}) {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      viewBox="0 0 24 24"
      fill="none"
      className={svgClassName}
      style={{
        '--size': `calc(1px * ${size})`,
        '--vb-height': '24px',
        '--vb-width': '24px',
        width: 'var(--size)',
        height: 'calc((var(--vb-height) / var(--vb-width)) * var(--size))',
        pointerEvents: 'none',
        ...svgStyle,
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={currentColor} d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/>
    </svg>
  )
}
