const config = {
  envName: process.env.REACT_APP_ENV,
  apiUrls: {
      getSessionInfo: `${process.env.REACT_APP_API_URL_GET_SESSION_INFO}?${new Date().getTime()}`,
      createRecordingSession: `${process.env.REACT_APP_API_URL_CREATE_RECORDING_SESSION}`,
  },
  axiosOptions: {
      withCredentials: process.env.REACT_APP_AXIOS_OPTION_WITH_CREDENTIALS === 'true'
  }
};

export default config;
